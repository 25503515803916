export const apex_invest_generic = (settings, qrcode) => ({
  card: `
  <div class="badge-container">
 <div class="info-container">
     <div class="wrapper">
         <div id="fullnameWrapper">
         <p id="fullnameText" class="fullname">#_FULLNAME_#</p>
         </div>
          <div id="organizationWrapper">
         <p id="organization" class="organization">#_ORGANIZATION_#</p>
         </div>
         <div class="radio">
              <div class='radioValue'>#_RADIO_#</div>
            </div>
     </div>
     </div>
</div>
<script>
             function checkOverflow() {
                 const div = document.getElementById('meetings');
                 const text = document.getElementById('meetings_text');

                 const fullnameWrapper = document.getElementById('fullnameWrapper');
                 const fullnameText = document.getElementById('fullnameText');

                 const organizationWrapper = document.getElementById('organizationWrapper');
                 const organization = document.getElementById('organization');

                 if (text.offsetHeight > div.offsetHeight || text.offsetWidth > div.offsetWidth) {
                     let fontSize = parseInt(window.getComputedStyle(text).fontSize);
                     while (text.offsetHeight > div.offsetHeight || text.offsetWidth > div.offsetWidth) {
                         fontSize -= 1;
                         text.style.fontSize = fontSize + 'px';
                     }
                 }

                 if (fullnameText.offsetHeight > fullnameWrapper.offsetHeight || fullnameText.offsetWidth > fullnameWrapper.offsetWidth) {
                     let fontSize = parseInt(window.getComputedStyle(fullnameText).fontSize);
                     while (fullnameText.offsetHeight > fullnameWrapper.offsetHeight || fullnameText.offsetWidth > fullnameWrapper.offsetWidth) {
                         fontSize -= 1;
                         fullnameText.style.fontSize = fontSize + 'px';
                     }
                 }
                 if (organization.offsetHeight > organizationWrapper.offsetHeight || organization.offsetWidth > organizationWrapper.offsetWidth) {
                     let fontSize = parseInt(window.getComputedStyle(organization).fontSize);
                     while (organization.offsetHeight > organizationWrapper.offsetHeight || organization.offsetWidth > organizationWrapper.offsetWidth) {
                         fontSize -= 1;
                         organization.style.fontSize = fontSize + 'px';
                     }
                 }
             }
             window.addEventListener('load', checkOverflow);
             window.addEventListener('resize', checkOverflow);
             </script>
 `,
  style: `
   <style>
   * {
   font-family: 'Myriad Pro', sans-serif;
   border-radius: 0;
   margin: 0;
   padding: 0;
   font-weight: 400;
   visibility: visible;
   color: #000;
}

.wrapper {
   text-align: center;
   height: 100%;
   width: 100%;
 }
#fullnameWrapper{
   margin-top:40mm;
}
.mb-2 {
   margin-bottom: 2px !important;
}

.badge-container {
   width: 102mm !important;
   height: 296mm !important;
   margin-right: auto !important;
   margin-left: auto !important;
   text-align: center !important;
   transform: rotateZ(180deg) !important;
}

.info-container {
   display: flex !important;
   flex-direction: column !important;
   align-items: center !important;
   background: url('https://library.micetribe.com/images/Manager.jpg') no-repeat 50% 50%;
   height: 100%;
   width: 100%;
   background-size: contain;
   position: relative;
}


.radioValue {
  position: absolute;
  right: 3.1mm;
  top: 122.98mm;
}




.fullname {
   padding-top: 13.528mm;
   font-size: 10.47mm;
   font-weight: 400 !important;
   overflow: hidden !important;
   color: #000000 !important;
   text-transform: capitalize;
}
.organization {
   padding-top: 2mm;
   font-size: 6.83mm;
   font-weight: 400 !important;
   overflow: hidden !important;
   color: #000000 !important;
   text-transform: capitalize;
}

#organizationWrapper{
 max-height: 16mm;
}

.organizer {
   font-size: 9.614mm !important;
   font-weight: bolder !important;
   overflow: hidden !important;
   color: #E26B13 !important;
   text-transform: uppercase;
   padding: 5.116mm 22.225mm;
   margin-top: 11.11mm;
}

.qr-text {
   text-transform: uppercase !important;
   margin: 0 !important;
   overflow: hidden !important;
   font-weight: 400 !important;
   color: #000000 !important;
}

.qr-code {
   width: 18mm !important;
   margin: auto;
}

.print-page {
   display: block
}

.page-break {
   display: block;
   page-break-before: always
}


@media print {
   .page-break {
       display: block;
       page-break-before: always
   }
}
   @font-face {
     font-family: 'Myriad Pro';
     src: url('https://library.micetribe.com/images/MYRIADPRO-REGULAR.OTF') format('truetype');
 }
   </style>
     `
})
