import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { RegistrationDocument } from '@app/modules/@micetribe/forms/models/registration-document.model'
import { MiceForm, MiceFormTemplate } from '@app/modules/@micetribe/forms/models/form.model'
import { SearchResult } from '../../search/models/search.result.model'
import { map } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { HeaderService } from '@app/modules/home/services/header.service'
import { BadgeTemplate } from '@app/modules/event/interfaces/badge-template.interface'
import { AuthService } from '@app/services/auth.service'
import { format } from 'date-fns'
import { Subject } from 'rxjs'
import { BadgeDrawer } from '../components/badge/badge-drawer'
import { combinedNouns, femaleSalutations, maleSalutations, salutations } from '../../forms/data/fullname-data'

interface PrintInfo {
  persona: string
  fullname: string
  job: string
  organization: string
  qr: string
}

export declare type Printable = PrintInfo | Partial<PrintInfo> | { [key: string]: string | number | boolean }

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  printRecord$ = new Subject<{ id: string; prints: any[] }>()

  public environment = environment
  private style = `<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap" rel="stylesheet"><style>*{font-family:'Open Sans';border-radius:0;margin:0;padding:0;font-weight:500;visibility:visible;color:#000!important;text-align:center}.badge-container{width:82mm;height:127mm;margin-right:auto;margin-left:auto;text-align:center;justify-content:center;transform:rotateZ(180deg)}.info-container{display:flex;flex-direction:column;align-items:center;justify-content:center;padding-top:52mm}.persona{font-weight:700;font-size:9mm;font-weight:700;line-height:8mm;text-transform:uppercase;padding:0 0 2mm;margin:0;max-height:10mm;overflow:hidden}.fullname,.job,.organization,.qr-text{text-transform:capitalize;font-size:4.5mm;line-height:4.5mm;padding-bottom:1mm;margin-top:0;max-height:10mm;overflow:hidden;font-weight:700;color:000000}.organization{font-size:3mm;line-height:3mm;padding-bottom:1mm;font-weight:400;text-transform:none}.qr-code{padding-top:1mm;width:18mm;padding-bottom:1mm}.print-page{display:block}.qr-text{font-weight:400;font-size:2.5mm;line-height:2.5mm;padding:0;margin:0}.page-break{display:block;page-break-before:always}@media print{.page-break{display:block;page-break-before:always}}</style>`
  private script = `<script type="text/javascript">
  window.onload = async function(){
    setTimeout(() => {
      await window.print()
      window.onafterprint = window.close
    }, 500);
  }
  </script>`

  private defaultTemplate(registration: Printable): MiceFormTemplate {
    return {
      persona: `<div class="persona">${registration.persona}</div>`,
      fullname: `<div class="fullname">${registration.fullname}</div>`,
      job: `<div class="job">${registration.job}</div>`,
      organization: `<div class="organization">${registration.organization}</div>`,
      qr: `<img src="${BadgeDrawer.getQrImgSrc(registration.qr)}" class="qr-code"><div class="qr-text">${registration.qr}</div>`,
      script: '',
      style: `<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap" rel="stylesheet"><style>*{font-family:'Open Sans';border-radius:0;margin:0;padding:0;font-weight:500;visibility:visible;color:#000!important;text-align:center}.badge-container{width:82mm;height:127mm;margin-right:auto;margin-left:auto;text-align:center;justify-content:center;transform:rotateZ(180deg)}.info-container{display:flex;flex-direction:column;align-items:center;justify-content:center;padding-top:52mm}.persona{font-weight:700;font-size:9mm;font-weight:700;line-height:8mm;text-transform:uppercase;padding:0 0 2mm;margin:0;max-height:10mm;overflow:hidden}.fullname,.job,.organization,.qr-text{text-transform:capitalize;font-size:4.5mm;line-height:4.5mm;padding-bottom:1mm;margin-top:0;max-height:10mm;overflow:hidden;font-weight:700;color:000000}.organization{font-size:3mm;line-height:3mm;padding-bottom:1mm;font-weight:400;text-transform:none}.qr-code{padding-top:1mm;width:18mm;padding-bottom:1mm}.print-page{display:block}.qr-text{font-weight:400;font-size:2.5mm;line-height:2.5mm;padding:0;margin:0}.page-break{display:block;page-break-before:always}@media print{.page-break{display:block;page-break-before:always}}</style>`
    }
  }

  private evalSection(registration: Printable, form: MiceForm, section: string, environment = this.environment) {
    let template = null
    let useDefault = false

    try {
      template = JSON.parse(JSON.parse(form.badgeTemplate))
    } catch (error) {
      template = this.defaultTemplate(registration)
    }
    if (!template) {
      template = this.defaultTemplate(registration)
      useDefault = true
    }

    if (section === 'style' || section === 'script') {
      const sectionTemp = useDefault ? template[section] : eval(template[section])
      return sectionTemp !== '' ? sectionTemp : this[section]
    }
    const evaluated = useDefault ? template[section] : eval(template[section])
    return typeof evaluated === 'undefined' ? '' : evaluated
  }

  personaSection = (registration: Printable, form: MiceForm) => this.evalSection(registration, form, 'persona')
  fullnameSection = (registration: Printable, form: MiceForm) => this.evalSection(registration, form, 'fullname')
  jobSection = (registration: Printable, form: MiceForm) => this.evalSection(registration, form, 'job')
  organizationSection = (registration: Printable, form: MiceForm) => this.evalSection(registration, form, 'organization')
  qrSection = (registration: Printable, form: MiceForm) => this.evalSection(registration, form, 'qr')
  styleSection = (registration: Printable, form: MiceForm) => this.evalSection(registration, form, 'style')
  scriptSection = (registration: Printable, form: MiceForm) => this.evalSection(registration, form, 'script')

  constructor(private http: HttpClient, private headerService: HeaderService, private authService: AuthService) {}

  public getSearchResultTemplate(searchResult: SearchResult, self: boolean = false) {
    return searchResult.getPrintDocuments(self).pipe(
      map((printDocuments) => {
        const templates = printDocuments.map((element) =>
          searchResult.form.type === 100 ? this.genericTemplate(element) : this.badgeTemplate(element, searchResult.printForm)
        )
        return `
        ${this.styleSection(printDocuments[0], searchResult.printForm)}
        ${this.mergeTemplates(templates)}
        ${this.scriptSection(printDocuments[0], searchResult.printForm)}`
      })
    )
  }

  public getRegistrationsTemplate(registration: RegistrationDocument, form: MiceForm) {
    if (form.type === 100) {
      return this.genericTemplate({
        category: registration.fields.category,
        line1: registration.fields.line1,
        line2: registration.fields.line2,
        line3: registration.fields.line3,
        line4: registration.fields.line4,
        qrSrc: `${BadgeDrawer.getQrImgSrc(registration.barcode)}`,
        qr: registration.barcode
      })
    } else {
      const documents = []
      if (!!form.subForms && form.subForms.length) {
        registration.subRegistrations.forEach((element) => {
          documents.push({
            persona: form.name,
            fullname: element.fields.fullname || '',
            job: element.fields.job_title || '',
            organization:
              registration.event === 'heya' && registration.form === 'exh' ? registration.fields.name : element.fields?.organization || '',
            qr: element.barcode
          })
        })
      }
      if (!documents.length) {
        documents.push({
          persona: form.name,
          fullname: registration.fields.fullname || '',
          job: registration.fields.job_title || '',
          organization: registration.fields?.organization || '',
          qr: registration.barcode
        })
      }
      const templates = documents.map((element) => this.badgeTemplate(element, form))
      return `${this.styleSection(documents[0], form)} ${this.mergeTemplates(templates)} ${this.scriptSection(documents[0], form)}`
    }
  }

  private badgeTemplate(registration: PrintInfo | Partial<PrintInfo>, form: MiceForm): string {
    return `
    <div class="badge-container">
      <div class="info-container">
        ${this.personaSection(registration, form)}
        ${this.fullnameSection(registration, form)}
        ${this.jobSection(registration, form)}
        ${this.organizationSection(registration, form)}
        ${this.qrSection(registration, form)}
      </div>
    </div>
    <div class="page-break"></div>`
  }

  private mergeTemplates(templates: string[]) {
    return `
    <div class="print-page">
      ${templates.join('')}
    </div>
  `
  }

  private genericTemplate({ category, line1, line2, line3, line4, qrSrc, qr }) {
    return `${this.style}
  <div class="print-page"> <div class="badge-container"> <div class="info-container"> <div class="persona"> ${category}</div><div class="fullname"> ${line1}</div><div class="job"> ${line2}</div><div class="organization"> ${line3}</div><div class="organization"> ${line4}</div> <div class="qr-code"> #_QRCODE_# </div> <div class="qr-text"> ${qr}</div></div><div class="page-break"></div></div></div>
  `
  }

  public getBadgeDocument(form: MiceForm) {
    return this.http.get(
      `${environment.api}/v3/registrations/badge/form/${this.headerService.workspaceSlug}/${this.headerService.eventSlug}/${form.slug}`
    )
  }

  public previewBagdeTemplate(badgeTemplate: BadgeTemplate) {
    return this.http.post(`${environment.api}/v3/registrations/badge/preview`, badgeTemplate)
  }

  public updateBadgeTemplate(formId: number, badgeTemplate: BadgeTemplate) {
    return this.http.patch(
      `${environment.api}/v3/plan/contactless/forms`,
      { id: formId, badge_template: badgeTemplate },
      {
        headers: {
          appId: '2',
          token: this.authService.user.token
        }
      }
    )
  }


  /**
   * Replace the tags in the card of the template (or demo badge template) with provided registration data
   * @param card string
   * @param registration RegistrationDocument
   * @param persona MiceForm
   * @param demoBadgeTemplate BadgeTemplate
   * @returns dataFilledCard string
   */
  public fillCard(card: string, registration: RegistrationDocument, persona: MiceForm, demoBadgeTemplate: BadgeTemplate = null) {
    this.handlePrintinForGBEC(registration, persona)
    const isChildForm = !!persona.parent
    let badgeTemplate = demoBadgeTemplate ? demoBadgeTemplate : (JSON.parse(persona.badgeTemplate) as BadgeTemplate)
    if (
      registration['workspace'] === 'qacreates' &&
      ['crrunway', 'crrunwayaab', 'crrunwayaabm38byke', 'crrunwayqm'].includes(registration.event)
    ) {
      const zoneAccessMap = new Map<String, number>([
        ['STAGE', 0],
        ['BACKSTAGE', 1],
        ['PITCH', 2],
        ['VVIP', 3],
        ['HOSPITALITY', 4],
        ['SKYBOX', 5],
        ['MEDIA', 6],
        ['TECHNICAL-AREA', 7]
      ])
      let categoriesMarkup = ''
      ;((registration.fields['checkbox'] as Array<any>) ?? []).forEach((el: string, i) => {
        categoriesMarkup += `<div class="box ${el.trim().toUpperCase().replace(' ', '-')}"><p>${zoneAccessMap.get(
          el.trim().toUpperCase().replace(' ', '-')
        )}</p><p>${el.trim()}</p></div>`
      })
      const eventName = new Map([
        ['crrunway', 'Venue'],
        ['crrunwayaab', 'AAB'],
        ['crrunwayaabm38byke', 'Auditoire'],
        ['crrunwayqm', 'QM']
      ])
      card = card.replace(/#_ZONE_ACCESS_#/g, categoriesMarkup).replace(/#_QFU_EVENT_#/g, eventName.get(registration.event))
      if (isChildForm) {
        card = card.replace(/#_ORGANIZATION_#/g, registration.parent.fields['organization'] as string)
      }
      if (['crrunwayaab', 'crrunwayaabm38byke', 'crrunwayqm'].includes(registration.event)) {
        card = card.replace(/#_RADIO1_#/g, registration.fields['radio'] as string)
        card = card.replace(/#_UPLOAD_#/g, registration.fields['upload1'] as string)
      }
    }
    if (this.headerService.activeEvent.eventDetails.slug.toLowerCase() === 'gimsq23' && registration.form === 'exh_members') {
      if (registration.fields['job_title'] === 'VIP') {
        card = card.replace('#_PERSONA_#"', 'Vip"').replace(' #_PERSONA_#"', ' Vip"')
        card = card.replace('>#_PERSONA_#<', `>${registration.fields['job_title']}<`).trim()
      }
    }
    if (this.headerService.activeEvent.eventDetails.slug.toLowerCase() === 'wise2021') {
      card = card
        .replace(
          /#_ID_#/g,
          (registration.fields['id_card_number'] as string) ||
            (registration.fields['passport_number'] as string) ||
            (registration.fields['numeric'] as string) ||
            (registration.fields['input'] as string) ||
            ''
        )
        .replace(
          /#_PROFILE_PHOTO_URL_#/g,
          (registration.fields['profile_photo_url'] as string) ||
            (registration.fields['upload'] as string) ||
            'https://library.micetribe.com/images/person.jpg'
        )
        .replace(
          /#_BADGE_TYPE_#/,
          registration.fields['badge_type']
            ? (registration.fields['badge_type'] as string).trim().replace(' ', '_').toLowerCase()
            : registration.form === 'vis0' || registration.form === 'vis'
            ? 'ted'
            : 'delegate'
        )
        .replace(
          /#_BADGE_TYPE_#/,
          registration.fields['badge_type']
            ? (registration.fields['badge_type'] as string).trim().replace('_', ' ')
            : registration.form === 'vis0' || registration.form === 'vis'
            ? 'ted'
            : 'delegate'
        )
        .trim()
    }
    if (this.headerService.activeEvent.eventDetails.workspace.slug === 'qf' && this.headerService.activeEvent.eventDetails.slug === 'wise' &&  ['onsite-registration','import'].includes(registration.form)) {
      card = card.replace(/#_PHOTO_#/g, registration.fields['photo'] ? registration.fields['photo'].toString() : 'https://library.micetribe.com/images/user_pic.jpeg')
      const badgeTypeMappings = {
        'speaker': { categoryBackground: 'speaker_color', arBadgeType: 'متحدث' },
        'school': { categoryBackground: 'speaker_color', arBadgeType: 'مدرسة' },
        'contributor': { categoryBackground: 'speaker_color', arBadgeType: 'مشارك' },
        'delegate': { categoryBackground: 'speaker_color', arBadgeType: 'مشارك' },
        'eaa delegate': { categoryBackground: 'eeaspeaker_color', arBadgeType: 'وفد مؤسسة التعليم فوق الجميع' },
        'eaa speaker': { categoryBackground: 'eeaspeaker_color', arBadgeType: 'متحدث مؤسسة التعليم فوق الجميع' },
        'eaa team': { categoryBackground: 'eeaspeaker_color', arBadgeType: 'فريق مؤسسة التعليم فوق الجميع' },
        'media': { categoryBackground: 'media_color', arBadgeType: 'وسائل الإعلام' },
        'qatar foundation': { categoryBackground: 'qf_color', arBadgeType: 'مؤسسة قطر' },
        'wise team': { categoryBackground: 'wise_team_color', arBadgeType: `فريق &quot;وايز&quot;` },
        'staff': { categoryBackground: 'speaker_color', arBadgeType: `فريق العمل` },
      };
      const badgeType: string = registration.fields['badge_type'].toString().toLowerCase();
      if (badgeTypeMappings[badgeType]) {
        const { categoryBackground, arBadgeType } = badgeTypeMappings[badgeType];
        card = card.replace(/#_CATEGORYBACKGROUND_#/g, categoryBackground);
        card = card.replace(/#_AR_BADGE_TYPE_#/g, arBadgeType);
      } else {
        card = card.replace(/#_CATEGORYBACKGROUND_#/g, '');
        card = card.replace(/#_AR_BADGE_TYPE_#/g, '');
        card = card.replace(/#_BADGE_TYPE_#/g, '');
      }
    }

    if(this.headerService.activeEvent.eventDetails.workspace.slug === 'agl' && this.headerService.activeEvent.eventDetails.slug === 'aitcoc24'){
      const isEnabled = registration.fields['radio'] === 'No' ? true : false;
      card = card.replace(/#_RADIO_#/g, isEnabled ? '<img width="40" height="40" src="https://library.micetribe.com/images/notAllowed.png" >' : '')
    }

    if (isChildForm) {
      card = card
        .replace(/#_PERSONA_#/g, persona.parent.name)
        .replace(
          /#_ORGANIZATION_#/g,
          (registration.parent.fields['organization'] as string) ?? (registration.parent.fields['name'] as string)
        )
    }
    if (badgeTemplate) {
      card = card
        .replace(/#_BARCODE_#/g, badgeTemplate.qr.enabled ? registration.barcode : '')
        .replace(/#_PERSONA_#/g, badgeTemplate.persona.enabled ? persona.name : '')
        .replace(
          /#_DTCM_BARCODE_#/g,
          badgeTemplate.dtcm?.showText && registration.third_parts.dtcm?.barcode ? registration.third_parts.dtcm.barcode : ''
        )
        .replace(
          /#_DTCM_BARCODE_IMAGE_#/g,
          badgeTemplate.dtcm?.enabled && registration.third_parts.dtcm?.barcode
            ? `<img src="${registration.barcode_drawer.barcode_src}" class="dtcm-code">`
            : ''
        )
        .replace(/#__PERSONA_NAME__#/g ,persona.name )
    } else {
      card = card.replace(/#_BARCODE_#/g, registration.barcode).replace(/#_PERSONA_#/g, persona.name)
    }
    card = card.replace(/#_TODAY_#/g, format(new Date(), 'dd MMMM yyyy'))
    if (registration.products?.length) {
      const productCategories = new Set(registration.products.map((o) => o.product_type))
      const categories = Array.from(productCategories)
      let categoryTags = ''
      categories.forEach((category) => (categoryTags += `<p>${category}</p>`))
      card = card.replace(/#_CATEGORY_#/g, categoryTags)
    }
    const templateName = registration.event ? BadgeDrawer.getTemplateName(registration) : 'default-badge'
    if(['default-badge','via_sct24','cautro_mewared','qf_wmtcq24_local','qf_wmtcq24_international'].includes(templateName)){
      const qrSrc = BadgeDrawer.getQrImgSrc(registration.barcode)
      card = card.replace(`#_QRCODE_#`, `<img class="qrcode-image" src="${qrSrc}" />`)
    }
    
    persona.formElements.forEach((element) => {
      if (element.name.startsWith('datetime')) {
        const date = registration.fields[element.name] as string
        const printedDate = `${new Date(date).getDate()} ${new Date(date).toLocaleString("en-US", { month: "long" })} ${new Date(date).getFullYear()}`
        card = card.replace(new RegExp(`#_${element.name.toUpperCase()}_#`, 'g'), printedDate)
      }
      card = card.replace(
        new RegExp(`#_${element.name.toUpperCase()}_#`, 'g'),
        this.splitFullname(registration.fields[element.name] as string, element.name, registration)
      )
    })
    if (registration.workspace === 'qga' && registration.event === 'cbqm27' && registration.form === 'de-boer-chalet') {
      const uploadField = registration.fields['upload'] || registration.fields['upload1'];
      if (uploadField) {
        card = card.replace(/#_UPLOAD_#/g, uploadField.toString());
      }
    }
    if(registration.workspace ==='cxl' && registration.event.toLowerCase() == 'cxlive2024' &&   ['delegate', 'speaker'].includes(registration.form.toLowerCase())){
      card = card.replace('#_LCINPUT_#', registration.fields['input'] ? `LC - ${registration.fields['input']}` : '').replace('#_LRINPUT_#', registration.fields['input1'] ? `LR - ${registration.fields['input1']}` : '');
    }
    card = card
      .replace(/#\_(.*?)\_#/g, '')
    return card
  }
  splitFullname(field: string, elementName, registration: RegistrationDocument): string {
    if (
      registration.workspace === 'qbec' &&
      registration.event === 'gimsq23' &&
      elementName === 'fullname' &&
      elementName &&
      field &&
      registration.form !== 'vis'
    ) {
      const fullnameSalutations = [...maleSalutations, ...femaleSalutations, ...salutations, ...combinedNouns]
      let counter = 0
      field.split(' ').forEach((segment) => {
        if (fullnameSalutations.includes(segment.replace('.', '').toLowerCase())) counter++
      })
      field = field.replace(field.split(' ')[counter], field.split(' ')[counter] + '<br>')
    }
    return field ?? ''
  }

  handlePrintinForGBEC(registration, persona) {
    if (
      (registration.workspace === 'dmr' && registration.event === 'gimsq23' && registration.form !== 'vis') ||
      (registration.workspace === 'qbec' && registration.event === 'gimsq23' && registration.form !== 'vis')
    ) {
      persona.formElements.push({
        "label": "company",
        "name": "company",
        "type": "company",
        "typeName": "company",
      })
    }
  }
}

